import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserGuard} from './guards/user.guard';
import {APP_ROUTES} from './app.navigation';
import {RegisterPonyComponent} from './public/pages/auction/pages/ponies/register-pony/register-pony.component';

const routes: Routes = [

  // Something went wrong if we get here
  {
    path: APP_ROUTES.apiOffLine,
    loadComponent: () => import('./public/pages/api-off-line/api-off-line.component').then(c => c.ApiOffLineComponent),
  },

  // Private 'beheer' pages
  {
    path: APP_ROUTES.private,
    canActivate: [UserGuard],
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)
  },

  // Public accessible pages
  {
    path: APP_ROUTES.auction,
    loadComponent: () => import('./public/pages/auction/auction.component').then(c => c.AuctionComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: APP_ROUTES.currentYear
      },
      {
        path: APP_ROUTES.currentYear,
        loadComponent: () => import('./public/pages/auction/pages/current-year/current-year.component').then(c => c.CurrentYearComponent),
      },
      {
        path: APP_ROUTES.foals,
        loadComponent: () => import('./public/pages/auction/pages/ponies/foals/foals.component').then(c => c.FoalsComponent),
      },
      {
        path: APP_ROUTES.adolescents,
        loadComponent: () => import('./public/pages/auction/pages/ponies/adolescents/adolescents.component').then(c => c.AdolescentsComponent),
      },
      {
        path: APP_ROUTES.adults,
        loadComponent: () => import('./public/pages/auction/pages/ponies/adults/adults.component').then(c => c.AdultsComponent),
      },
      {
        path: APP_ROUTES.register,
        loadComponent: () => import('./public/pages/auction/pages/ponies/register-pony/register-pony.component').then(c => c.RegisterPonyComponent),
        canDeactivate: [(component: RegisterPonyComponent) => {
          if (component.getHasUnsentChanges()) {
            return component.showConfirmDialog();
          } else {
            return true;
          }
        }],
      },
      {
        path: APP_ROUTES.afterSales,
        loadComponent: () => import('./public/pages/auction/pages/after-sales/after-sales.component').then(c => c.AfterSalesComponent),
      },
      {
        path: APP_ROUTES.accommodations,
        loadComponent: () => import('./public/pages/auction/pages/accommodations/accommodations.component').then(c => c.AccommodationsComponent),
      },
      {
        path: APP_ROUTES.pastEditions,
        loadComponent: () => import('./public/pages/auction/pages/past-editions/past-editions.component').then(c => c.PastEditionsComponent),
      },
      {
        path: APP_ROUTES.pastEditions,
        loadComponent: () => import('./public/pages/auction/pages/past-editions/past-editions.component').then(c => c.PastEditionsComponent),
      },
      {
        path: APP_ROUTES.termsAndConditions,
        loadComponent: () => import('./public/pages/auction/pages/terms-and-conditions/terms-and-conditions.component').then(c => c.TermsAndConditionsComponent),
      },
      {
        path: ':ponyId',
        loadComponent: () => import('./public/pages/auction/pages/ponies/public-pony-detail/public-pony-detail.component').then(c => c.PublicPonyDetailComponent)
      },
    ]
  },
  {
    path: APP_ROUTES.news,
    loadComponent: () => import('./public/pages/news/news.component').then(c => c.NewsComponent),
  },
  {
    path: APP_ROUTES.aboutUs,
    loadComponent: () => import('./public/pages/about-us/about-us.component').then(c => c.AboutUsComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: APP_ROUTES.shetlandPonySelectSale
      },
      {
        path: APP_ROUTES.shetlandPonySelectSale,
        loadComponent: () => import('./public/pages/about-us/pages/shetland-pony-select-sale/shetland-pony-select-sale.component').then(c => c.ShetlandPonySelectSaleComponent),
      },
      {
        path: APP_ROUTES.history,
        loadComponent: () => import('./public/pages/about-us/pages/history/history.component').then(c => c.HistoryComponent),
      },
    ]
  },
  {
    path: APP_ROUTES.supportUs,
    loadComponent: () => import('./public/pages/support-us/support-us.component').then(c => c.SupportUsComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: APP_ROUTES.advertisers
      },
      {
        path: APP_ROUTES.advertisers,
        loadComponent: () => import('./public/pages/support-us/pages/advertisers/advertisers.component').then(c => c.AdvertisersComponent),
      },
      {
        path: APP_ROUTES.friendsOfTheAuction,
        loadComponent: () => import('./public/pages/support-us/pages/friends-of-the-auction/friends-of-the-auction.component').then(c => c.FriendsOfTheAuctionComponent),
      },
    ]
  },

  // Fallback
  {
    path: '**',
    redirectTo: APP_ROUTES.auction
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRouting {
}
