<h5 class="header">
  <a routerLink="/{{APP_ROUTES.supportUs}}/{{APP_ROUTES.friendsOfTheAuction}}">
    <mat-icon inline color="warn">volunteer_activism</mat-icon>
    <span class="color--warn">{{ 'generic.Vrienden van de veiling' | translate }}</span>
  </a>
</h5>

<div *ngIf="friends$ | async as friends"
     class="marquee-container">

  <ng-container *ngIf="friends.length * 7 as animationSeconds">

    <!-- plot list 2 times to make do the marquee magic -->
    <ng-container *ngTemplateOutlet="listOfFriends"></ng-container>
    <ng-container *ngTemplateOutlet="listOfFriends"></ng-container>
    <ng-container *ngTemplateOutlet="listOfFriends"></ng-container>

    <ng-template #listOfFriends>
      <div class="friends"
           [style.animation-duration]="animationSeconds + 's'">
        <ng-container *ngFor="let friend of friends; let index = index">

          <a *ngIf="friend.isCustomLink; else showNormalFriend"
             routerLink="/{{APP_ROUTES.supportUs}}/{{APP_ROUTES.friendsOfTheAuction}}"
             class="friend color--primary">
            <mat-icon inline>person_add</mat-icon>
            <b><span>{{ friend.fullName }}</span></b>
          </a>

          <ng-template #showNormalFriend>
            <a *ngIf="friend.website as website; else showNonLinkFriend"
               href="{{website}}"
               target="_blank"
               class="friend">
              <mat-icon inline>open_in_new</mat-icon>
              <ng-container [ngTemplateOutlet]="friendContent" [ngTemplateOutletContext]="{friend}"></ng-container>
            </a>
          </ng-template>


          <ng-template #showNonLinkFriend>
            <span class="friend">
              <ng-container [ngTemplateOutlet]="friendContent" [ngTemplateOutletContext]="{friend}"></ng-container>
            </span>
          </ng-template>

          <ng-template #friendContent let-friend="friend">
            <span>{{ friend.fullName }}{{ friend.companyName ? (', ' + friend.companyName) : '' }}{{ friend.city ? (' (' + friend.city + ')') : '' }}</span>
          </ng-template>

          <span class="color--gray-darker">&cir;</span>

        </ng-container>
      </div>
    </ng-template>

  </ng-container>

</div>
