import {Component, OnInit} from '@angular/core';
import {SharedModule} from '../../../../shared/shared.module';
import {APP_ROUTES} from '../../../../app.navigation';
import {Advertiser} from '../../../../types/advertiser.interface';
import {MatDialog} from '@angular/material/dialog';
import {PublicAdvertiserDialogComponent} from './public-advertiser-dialog/public-advertiser-dialog.component';
import {PublicAdvertisersService} from '../../../services/public-advertisers.service';

@Component({
  selector: 'app-public-footer-advertisers',
  templateUrl: './public-footer-advertisers.component.html',
  styleUrls: ['./public-footer-advertisers.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class PublicFooterAdvertisersComponent implements OnInit {
  protected readonly APP_ROUTES = APP_ROUTES;

  advertisers?: Advertiser[];

  constructor(
    private matDialog: MatDialog,
    private publicAdvertisersService: PublicAdvertisersService
  ) {
    this.publicAdvertisersService.init();
  }

  ngOnInit() {
    this.publicAdvertisersService.getAdvertisers().subscribe(advertisers => {
      if (advertisers?.length) {
        advertisers = advertisers.map(advertisers => {
          if (advertisers.website) {
            if (!advertisers.website.startsWith('http')) {
              advertisers.website = `http://${advertisers.website}`;
            }
          }
          return advertisers
        })
      }
      this.advertisers = this.shuffleAdvertisers(advertisers);
    });
  }

  private shuffleAdvertisers(advertisers: Advertiser[] | null) {
    if (!advertisers) return [];
    let currentIndex = advertisers.length;
    while (currentIndex != 0) {
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [advertisers[currentIndex], advertisers[randomIndex]] = [advertisers[randomIndex], advertisers[currentIndex]];
    }
    return advertisers;
  }

  onAdvertiserClick(advertiser: Advertiser) {
    this.matDialog.open(PublicAdvertiserDialogComponent, {data: advertiser});
  }
}
