import {Pony, ponyGenderOptions, ponyPregnantOptions} from '../../../app/types/pony.interface';
import {appConfig} from '../../../app-config';
import {customersMockData} from '../customers';
import * as moment from 'moment';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

const customerOne = customersMockData[0];
const customerTwo = customersMockData[1];
const customerThree = customersMockData[2];

export const poniesMockData = [
  {
    id: '123',
    name: 'Beppie',
    isSelected: true,
    isConfirmed: false,
    pregnantType: ponyPregnantOptions[2],
    admittanceDate: moment().subtract(1, 'year').minute(0).second(0).millisecond(0).toDate(),
    chipNumber: '123-123-123',
    gender: ponyGenderOptions[1],
    dateOfBirth: moment().subtract(3, 'year').minute(0).second(0).millisecond(0).toDate(),
    sellerCustomerId: customerTwo.id,
    sellerCustomerFirstName: customerTwo.firstName,
    sellerCustomerLastName: customerTwo.lastName,
    fundsDisbursed: false,
    buyerCustomerId: null,
    buyerCustomerFirstName: null,
    buyerCustomerLastName: null,
    sellingPrice: null,
    purchaseAgreementPDFUrl: null,
    purchaseAgreementExcelUrl: null,
    conceptPurchaseAgreementPDFUrl: null,
    conceptPurchaseAgreementExcelUrl: null,
    fundsReceived: false,
    lockedByUserId: null,
    lockedByUserName: null,
    catalogueNumber: '001',
    facebookUrl: null,
    admittanceFeePaid: 'Nee',
    color: marker('ponyColors.Vos'),
    ageType: 'FOAL',
    lifeNumber: '4123123',
    size: marker('ponySizes.Klein'),
    bioNL: 'Bio van dit mooie paardje, in het Nederlands. Bio van dit mooie paardje, in het Nederlands. Bio van dit mooie paardje, in het Nederlands. Bio van dit mooie paardje, in het Nederlands. Bio van dit mooie paardje, in het Nederlands.',
    photos: [
      {order: 1, path: 'https://images.spss.nu/mock-images/ponies/01.jpeg'},
      {order: 2, path: 'https://images.spss.nu/mock-images/ponies/02.jpeg'},
      {order: 3, path: 'https://images.spss.nu/mock-images/ponies/03.jpeg'}
    ],
    scanProofPath: 'https://images.spss.nu/mock-pdfs/ponies/drachtigheidsverklaring.pdf',
    scanProofFileName: 'drachtigheidsverklaring.pdf',
    familyTreePath: 'https://images.spss.nu/mock-images/family-trees/Beppie-stamboom-2024.jpg',
    familyTreeFileName: 'Beppie-stamboom-2024.jpg',
    isVisibleOnPublicPages: true,
    hasFoalPremium: true,
    hasYouthPremium: true,
    hasTwenterPremium: false,
    firstPremiumCount: 5,
    secondPremiumCount: 7,
    thirdPremiumCount: 0
  },

  {
    id: '789',
    isSelected: true,
    isConfirmed: true,
    pregnantType: ponyPregnantOptions[0],
    name: 'Sientje',
    admittanceDate: moment().subtract(1, 'year').subtract(4, 'months').minute(0).second(0).millisecond(0).toDate(),
    chipNumber: '789-789-789',
    gender: ponyGenderOptions[1],
    dateOfBirth: moment().subtract(1, 'year').subtract(4, 'months').minute(0).second(0).millisecond(0).toDate(),
    sellerCustomerId: customerTwo?.id,
    sellerCustomerFirstName: customerTwo?.firstName,
    sellerCustomerLastName: customerTwo?.lastName,
    fundsDisbursed: true,
    buyerCustomerId: customerThree?.id,
    buyerCustomerFirstName: customerThree?.firstName,
    buyerCustomerLastName: customerThree?.lastName,
    sellingPrice: 111,
    purchaseAgreementPDFUrl: '/assets/pdf/purchase-agreement.pdf',
    purchaseAgreementExcelUrl: '/assets/excel/purchase-agreement.xlsx',
    conceptPurchaseAgreementPDFUrl: '/assets/pdf/concept-purchase-agreement.pdf',
    conceptPurchaseAgreementExcelUrl: '/assets/excel/concept-purchase-agreement.xlsx',
    fundsReceived: false,
    lockedByUserId: appConfig.mockValues.loggedInUserId,
    lockedByUserName: appConfig.mockValues.loggedInUserName,
    catalogueNumber: '002',
    facebookUrl: 'https://www.facebook.com',
    admittanceFeePaid: 'Contant',
    color: marker('ponyColors.Paars'),
    ageType: 'ADULT',
    lifeNumber: '123456789',
    size: null,
    bioNL: 'Bio in het Nederlands',
    photos: [{order: 1, path: 'https://images.spss.nu/mock-images/ponies/03.jpeg'}],
    isVisibleOnPublicPages: true,
  },
  {
    id: '456',
    name: 'Black Beauty',
    isSelected: true,
    isConfirmed: true,
    admittanceDate: moment().subtract(2, 'year').minute(0).second(0).millisecond(0).toDate(),
    chipNumber: '456-456-456',
    gender: ponyGenderOptions[1],
    pregnantType: ponyPregnantOptions[1],
    pregnantUntil: moment().subtract(2, 'weeks').add(2, 'days').minute(0).second(0).millisecond(0).toDate(),
    pregnantBy: 'Hengst Harrie',
    dateOfBirth: moment().subtract(4, 'year').minute(0).second(0).millisecond(0).toDate(),
    sellerCustomerId: customerOne?.id,
    sellerCustomerFirstName: customerOne?.firstName,
    sellerCustomerLastName: customerOne?.lastName,
    fundsDisbursed: false,
    buyerCustomerId: null,
    buyerCustomerFirstName: null,
    buyerCustomerLastName: null,
    sellingPrice: null,
    purchaseAgreementPDFUrl: null,
    purchaseAgreementExcelUrl: null,
    conceptPurchaseAgreementPDFUrl: null,
    conceptPurchaseAgreementExcelUrl: null,
    fundsReceived: false,
    lockedByUserId: appConfig.mockValues.otherUserId,
    lockedByUserName: appConfig.mockValues.otherUserName,
    catalogueNumber: '003',
    facebookUrl: 'https://www.facebook.com',
    admittanceFeePaid: 'Bank',
    color: marker('ponyColors.Palominobont'),
    ageType: 'FOAL',
    lifeNumber: '22222222',
    size: marker('ponySizes.Groot'),
    bioFR: 'Bio en Francais',
    isVisibleOnPublicPages: true,
  },
  {
    id: '33323232',
    isSelected: true,
    isConfirmed: true,
    name: 'Japie',
    pregnantType: ponyPregnantOptions[2],
    admittanceDate: moment().subtract(1, 'year').subtract(4, 'weeks').minute(0).second(0).millisecond(0).toDate(),
    chipNumber: '777-666-444',
    gender: ponyGenderOptions[1],
    dateOfBirth: moment().subtract(2, 'year').subtract(4, 'days').minute(0).second(0).millisecond(0).toDate(),
    sellerCustomerId: customerThree?.id,
    sellerCustomerFirstName: customerThree?.firstName,
    sellerCustomerLastName: customerThree?.lastName,
    fundsDisbursed: false,
    buyerCustomerId: customerOne?.id,
    buyerCustomerFirstName: customerOne?.firstName,
    buyerCustomerLastName: customerOne?.lastName,
    sellingPrice: 222,
    purchaseAgreementPDFUrl: '/assets/pdf/purchase-agreement.pdf',
    purchaseAgreementExcelUrl: '/assets/excel/purchase-agreement.xlsx',
    fundsReceived: false,
    lockedByUserId: null,
    lockedByUserName: null,
    catalogueNumber: '004',
    facebookUrl: 'https://www.facebook.com',
    admittanceFeePaid: 'Contant',
    color: marker('ponyColors.Zwart'),
    lifeNumber: '33221',
    size: marker('ponySizes.Groot'),
    bioEN: 'Bio in English',
    photos: [{order: 1, path: 'https://images.spss.nu/mock-images/ponies/04.jpeg'}],
    isVisibleOnPublicPages: true,
  },
] as Pony[];
