import {MockDataInterface} from './mock-data.interface';
import {poniesMockData} from './data-sets/ponies/ponies';
import {customersMockData} from './data-sets/customers';
import {advertisersMockData} from './data-sets/advertisers/advertisers';
import {friendsOfTheAuctionMockData} from './data-sets/friends-of-the-auction';
import {staticDataMockData} from './data-sets/static-data';
import {staticAuctionEditions} from './data-sets/auction-editions';

export const mockData: MockDataInterface = {
  ponies: poniesMockData,
  customers: customersMockData,
  advertisers: advertisersMockData,
  friendsOfTheAuction: friendsOfTheAuctionMockData,
  staticData: staticDataMockData,
  auctionEditions: staticAuctionEditions
}
