<ng-container *ngFor="let menuItem of appMenuItems; let index = index">
  <ng-container *ngIf="!menuItem.loggedInUserRequired || userLoggedIn">

    <ng-template #rootItemContent let-menuItem="menuItem">
      <mat-icon [class.color--accent]="menuItem.loggedInUserRequired">{{ menuItem.icon }}</mat-icon>
      <span class="label">{{ menuItem.translateLabel ? (menuItem.label | translate) : menuItem.label }}</span>
      <span *ngIf="menuItem?.children?.length" style="font-size: 0.75em">&nbsp;▾</span>
    </ng-template>

    <a *ngIf="!menuItem?.children?.length; else showItemWithChildren"
       routerLink="/{{menuItem.routerLink}}"
       routerLinkActive="is-active"
       class="root-item">
      <ng-container [ngTemplateOutlet]="rootItemContent" [ngTemplateOutletContext]="{menuItem}"></ng-container>
    </a>

    <ng-template #showItemWithChildren>

      <span class="visually-hidden"
            routerLink="/{{menuItem.routerLink}}"
            routerLinkActive
            #rla="routerLinkActive"></span>

      <a [class.is-active]="rla.isActive"
         class="root-item"
         [matMenuTriggerFor]="subMenu">
        <ng-container [ngTemplateOutlet]="rootItemContent" [ngTemplateOutletContext]="{menuItem}"></ng-container>
      </a>

      <mat-menu #subMenu="matMenu"
                backdropClass="custom-mat-backdrop">

        <ng-container *ngFor="let subMenuItem of menuItem.children">

          <span *ngIf="subMenuItem.preDivider || subMenuItem.preDivider === ''"
                class="app-menu-divider">
            <span *ngIf="subMenuItem.preDivider !== ''"
                  class="app-menu-divider__label">{{ subMenuItem.preDivider | translate }}</span>
          </span>

          <a mat-menu-item
             routerLink="/{{menuItem.routerLink}}/{{subMenuItem.routerLink}}"
             routerLinkActive="is-active"
             #rla="routerLinkActive">
            <mat-icon
              class="color--{{rla.isActive ? (subMenuItem.loggedInUserRequired ? 'accent' : 'primary') : 'gray-dark'}}">{{ subMenuItem.icon }}
            </mat-icon>
            <span
              class="color--{{rla.isActive ? (subMenuItem.loggedInUserRequired ? 'accent' : 'primary') : 'gray-darkest'}}"
              [style.font-weight]="rla.isActive ? '500' : 'normal'">
              {{ subMenuItem.translateLabel ? (subMenuItem.label | translate) : subMenuItem.label }}<ng-container *ngIf="subMenuItem.staticDataKeyForCount as key"><span *ngIf="staticData && staticData[key] as count">&nbsp;({{ count }})</span></ng-container>
            </span>

          </a>
        </ng-container>

      </mat-menu>
    </ng-template>

    <ng-container *ngIf="appMenuItems[index + 1] as nextItem">
      <div *ngIf="menuItem.loggedInUserRequired && !nextItem.loggedInUserRequired"
           class="divider"></div>
    </ng-container>

  </ng-container>
</ng-container>

