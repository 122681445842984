<mat-nav-list>
  <ng-container *ngFor="let menuItem of appMenuItems; let index = index">
    <ng-container *ngIf="!menuItem.loggedInUserRequired || userLoggedIn">

      <mat-list-item *ngIf="!menuItem?.children?.length; else showItemWithChildren"
                     routerLink="/{{menuItem.routerLink}}"
                     routerLinkActive="is-active"
                     class="root-item"
                     [class.is-public]="!menuItem.loggedInUserRequired"
                     [class.is-private]="menuItem.loggedInUserRequired">
        <mat-icon matListItemIcon>{{ menuItem.icon }}</mat-icon>
        <span>{{ menuItem.translateLabel ? (menuItem.label | translate) : menuItem.label }}</span>
      </mat-list-item>

      <ng-template #showItemWithChildren>

        <span class="visually-hidden"
              routerLink="/{{menuItem.routerLink}}"
              routerLinkActive
              #rla="routerLinkActive"></span>

        <ng-container *ngVar="{isOpen: rla.isActive} as subMenuOpen">
          <mat-list-item class="root-item"
                         [class.is-active]="rla.isActive"
                         [class.is-public]="!menuItem.loggedInUserRequired"
                         [class.is-private]="menuItem.loggedInUserRequired"
                         (click)="subMenuOpen.isOpen = !subMenuOpen.isOpen">
            <mat-icon matListItemIcon>{{ menuItem.icon }}</mat-icon>
            <span>{{ menuItem.translateLabel ? (menuItem.label | translate) : menuItem.label }}</span>
            <mat-icon *ngIf="!subMenuOpen.isOpen" class="toggle-icon">expand_more</mat-icon>
            <mat-icon *ngIf="subMenuOpen.isOpen" class="toggle-icon">expand_less</mat-icon>
          </mat-list-item>

          <ng-container *ngIf="subMenuOpen.isOpen">

            <ng-container *ngFor="let subMenuItem of menuItem.children">

              <span *ngIf="subMenuItem.preDivider || subMenuItem.preDivider === ''"
                    class="app-menu-divider">
                <span *ngIf="subMenuItem.preDivider !== ''"
                      class="app-menu-divider__label">{{ subMenuItem.preDivider | translate }}</span>
              </span>

              <mat-list-item class="sub"
                             [class.is-public]="!menuItem.loggedInUserRequired"
                             [class.is-private]="menuItem.loggedInUserRequired"
                             routerLink="/{{menuItem.routerLink}}/{{subMenuItem.routerLink}}"
                             routerLinkActive="is-active">
                <mat-icon matListItemIcon>{{ subMenuItem.icon }}</mat-icon>
                {{ subMenuItem.translateLabel ? (subMenuItem.label | translate) : subMenuItem.label }}<ng-container *ngIf="subMenuItem.staticDataKeyForCount as key"><span *ngIf="staticData && staticData[key] as count">&nbsp;({{ count }})</span></ng-container>
              </mat-list-item>
            </ng-container>


            <mat-divider></mat-divider>
          </ng-container>
        </ng-container>
      </ng-template>

      <ng-container *ngIf="appMenuItems[index + 1] as nextItem">
        <ng-container *ngIf="menuItem.loggedInUserRequired && !nextItem.loggedInUserRequired">
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
        </ng-container>

      </ng-container>

    </ng-container>
  </ng-container>


</mat-nav-list>
