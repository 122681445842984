import {Component} from '@angular/core';
import {APP_ROUTES} from '../../../../app.navigation';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';

import {
  PublicFriendsOfTheAuctionService
} from '../../../services/public-friends-of-the-auction.service';
import {SharedModule} from '../../../../shared/shared.module';
import {FriendOfTheAuction} from '../../../../types/friend-of-the-auction.interface';
import {filter, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-public-footer-friends-of-the-auction',
  standalone: true,
  imports: [SharedModule, MatIconModule, RouterLink, TranslateModule],
  templateUrl: './public-footer-friends-of-the-auction.component.html',
  styleUrls: ['./public-footer-friends-of-the-auction.component.scss']
})
export class PublicFooterFriendsOfTheAuctionComponent {
  protected readonly APP_ROUTES = APP_ROUTES;
  friends$: Observable<FriendOfTheAuction[]>;

  constructor(
    private publicFriendsOfTheAuctionService: PublicFriendsOfTheAuctionService,
    private translatePipe: TranslatePipe,
  ) {
    this.publicFriendsOfTheAuctionService.init();

    this.friends$ = this.publicFriendsOfTheAuctionService.getFriends().pipe(
      filter(friends => !!friends),
      map(friends => {
        friends = this.shuffleFriends(friends);
        friends = this.addCustomLinks(friends);
        return friends;
      })
    )
  }

  private addCustomLinks(friends: FriendOfTheAuction[]): FriendOfTheAuction[] {
    const friendsCopy = [...friends];
    friends.forEach((friend, index) => {
      let amountAdded = 0;
      if(index % 5 === 0) {
        friendsCopy.splice(index + amountAdded, 0, {
          fullName: this.translatePipe.transform(marker('generic.Uw naam ook in deze lijst?')),
          isCustomLink: true
        } as FriendOfTheAuction);
        amountAdded++;
      }
    });
    return friendsCopy;
  }

  private shuffleFriends(friends: FriendOfTheAuction[] | null) {
    if(!friends) return [];
    let currentIndex = friends.length;
    while (currentIndex != 0) {
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [friends[currentIndex], friends[randomIndex]] = [friends[randomIndex], friends[currentIndex]];
    }
    return friends;
  }
}
