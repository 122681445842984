import {Component, OnDestroy} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {UserService} from '../../services/user.service';
import {AppMenuItems} from 'src/app/app.navigation';
import {Subscription} from 'rxjs';
import {StaticData, StaticDataService} from '../../services/static-data.service';

@Component({
  selector: 'app-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss'],
  standalone: true,
  imports: [SharedModule]
})
export class ToolbarMenuComponent implements OnDestroy {
  protected appMenuItems = AppMenuItems;

  private user$: Subscription;
  private staticDataService$: Subscription;

  userLoggedIn: boolean = false;
  staticData?: StaticData | null;

  constructor(
    private userService: UserService,
    private staticDataService: StaticDataService,
  ) {
    this.user$ = this.userService.user$.subscribe(user => {
      this.userLoggedIn = !!user;
    });
    this.staticDataService$ = this.staticDataService.getStaticData().subscribe(data => this.staticData = data)
  }

  ngOnDestroy(): void {
    this.user$.unsubscribe();
    this.staticDataService$.unsubscribe();
  }
}
