import {Base} from './base.interface';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export const ponyGenderOptions = [
  'Hengst',
  'Merrie',
];

export type PonyGender = typeof ponyGenderOptions[number];

export const ponyAdmittanceFeePaidOptions = [
  'Nee',
  'Bank',
  'Contant'
];

export type PonyAdmittanceFeePaid = typeof ponyAdmittanceFeePaidOptions[number];

export const ponyColorOptions: string[] = [
  marker('ponyColors.Bruin'),
  marker('ponyColors.Bruinbont'),
  marker('ponyColors.Cremedun'),
  marker('ponyColors.Cremello'),
  marker('ponyColors.Mushroom'),
  marker('ponyColors.Mushroombont'),
  marker('ponyColors.Palomino'),
  marker('ponyColors.Palominobont'),
  marker('ponyColors.Smokey'),
  marker('ponyColors.Valk'),
  marker('ponyColors.Valkbont'),
  marker('ponyColors.Vos Stekelharig'),
  marker('ponyColors.Vos'),
  marker('ponyColors.Vosbont'),
  marker('ponyColors.Wildkleur bruin'),
  marker('ponyColors.Wildkleur vosbont'),
  marker('ponyColors.Wildkleur zwart'),
  marker('ponyColors.Zwart'),
  marker('ponyColors.Zwartbont'),
  marker('ponyColors.Zwartschimmel')
];

export const ponySizeOptions: string[] = [
  marker('ponySizes.Mini'),
  marker('ponySizes.Klein'),
  marker('ponySizes.Midden'),
  marker('ponySizes.Groot')
];

export const ponyPregnantOptions: string[] = [
  marker('ponyPregnancyTypes.Niet gedekt'),
  marker('ponyPregnancyTypes.Gedekt'),
  marker('ponyPregnancyTypes.Drachtig met scanbewijs'),
];

export type PhotoListItem = {
  _tempId?: string | null;
  order: number | null;
  base64?: string | null;
  path?: string | null;
}

export interface Pony extends Base {
  _tempId?: string;

  // Calculated
  _conceptPurchaseAgreementReady?: boolean;
  _conceptPurchaseAgreementBlockingReasons?: string[];
  _purchaseAgreementReady?: boolean;
  _purchaseAgreementBlockingReasons?: string[];

  // Form Fields
  stableName?: string;
  lifeNumber?: string | null;
  isVisibleOnPublicPages?: boolean;
  isSelected?: boolean;
  isConfirmed?: boolean;
  admittanceDate?: Date;
  admittanceFeePaid?: PonyAdmittanceFeePaid;
  color?: string;
  size?: string;
  chipNumber?: string;
  dateOfBirth?: Date | null;
  gender?: PonyGender | null;
  isCastrated?: boolean;
  pregnantType?: string | null;
  pregnantUntil?: Date | null;
  pregnantBy?: string | null;
  bioNL?: string | null;
  bioEN?: string | null;
  facebookUrl?: string;
  // -- Premium
  hasFoalPremium?: boolean;
  hasYouthPremium?: boolean;
  hasTwenterPremium?: boolean;
  firstPremiumCount?: number;
  secondPremiumCount?: number;
  thirdPremiumCount?: number;

  // Uploads
  photosCount?: number;
  photos?: PhotoListItem[] | null;
  familyTreeBase64?: string | null;
  familyTreeFileName?: string | null;
  familyTreePath?: string;
  // -- Dekbewijs
  pregnantProofBase64?: string | null;
  pregnantProofFileName?: string | null;
  pregnantProofPath?: string;
  // -- Scanbewijs
  scanProofBase64?: string | null;
  scanProofFileName?: string | null;
  scanProofPath?: string;

  catalogueNumber?: string;

  sellerCustomerId?: string;
  sellerCustomerFirstName?: string;
  sellerCustomerLastName?: string;
  buyerCustomerId?: string;
  buyerCustomerFirstName?: string;
  buyerCustomerLastName?: string;
  purchaseAgreementPDFUrl?: string;
  conceptPurchaseAgreementPDFUrl?: string;
  purchaseAgreementExcelUrl?: string;
  conceptPurchaseAgreementExcelUrl?: string;

  // Funds
  sellingPrice?: number;
  fundsReceived?: boolean;
  fundsDisbursed?: boolean;

  previousPony?: Pony;
  nextPony?: Pony;
}
