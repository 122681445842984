import {Component, OnDestroy} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {MatListModule} from '@angular/material/list';
import {UserService} from '../../services/user.service';
import {AppMenuItems} from '../../app.navigation';
import {StaticDataService, StaticData} from '../../services/static-data.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  standalone: true,
  imports: [SharedModule, MatListModule]
})
export class SidenavMenuComponent implements OnDestroy {
  protected readonly appMenuItems = AppMenuItems;

  private user$: Subscription;
  private staticDataService$: Subscription;

  userLoggedIn: boolean = false;
  staticData?: StaticData | null;

  constructor(
    private userService: UserService,
    private staticDataService: StaticDataService,
  ) {
    this.user$ = this.userService.user$.subscribe(user => {
      this.userLoggedIn = !!user;
    });
    this.staticDataService$ = this.staticDataService.getStaticData().subscribe(data => this.staticData = data)
  }

  ngOnDestroy(): void {
    this.user$.unsubscribe();
    this.staticDataService$.unsubscribe();
  }
}
