import * as moment from 'moment';
import {AuctionEdition} from '../../app/services/auction-editions.service';

export const staticAuctionEditions = [
  {
    auctionDate: moment().year(2023).month(10).date(28).startOf('d').toDate(),
    deadlineDate: moment().year(2023).month(10).date(19).subtract(3, 'w').startOf('d').toDate(),
    edition: 12,
  },
  {
    auctionDate: moment().year(2025).month(10).date(4).startOf('d').toDate(),
    deadlineDate: moment().year(2025).month(10).date(4).subtract(3, 'w').startOf('d').toDate(),
    edition: 14,
  },
  {
    auctionDate: moment().year(2024).month(10).date(26).startOf('d').toDate(),
    deadlineDate: moment().year(2024).month(10).date(6).startOf('d').toDate(),
    edition: 13,
  }
] as AuctionEdition[]
