<mat-sidenav-container fullscreen>
  <mat-sidenav [autoFocus]="false"
               #sidenav>
    <app-sidenav-menu></app-sidenav-menu>
  </mat-sidenav>

  <app-version-checker></app-version-checker>

  <div class="app-wrapper">

    <mat-toolbar *ngIf="showMainNav | async"
                 class="mat-elevation-z2"
                 color="primary">

      <div class="logo-and-version">
        <span class="version">{{ environment.version.substring(2) }}</span>

        <img class="logo"
             alt="SPSS logo"
             ngSrc="../../assets/img/spss-logo.jpg"
             [priority]="true"
             width="158"
             height="100"
             (click)="handleLogoClick()"/>

        <ng-container *ngIf="isPrivatePage">
          <span *ngIf="loggedInUserCount$ | async as loggedInUserCount"
                class="logged-in-user-count"
                [class.one-user-active]="loggedInUserCount <= 1"
                [class.multiple-users-active]="loggedInUserCount > 1"
                matTooltip="# ingelogde beheerder(s): {{loggedInUserCount}}">
            <mat-icon inline>warning</mat-icon>
            <span>{{ loggedInUserCount }}</span>
          </span>
        </ng-container>
      </div>

      <app-toolbar-menu></app-toolbar-menu>

      <button *ngIf="i18nService.getLanguageAsObservable() | async as activeLanguage"
              class="selected-lang"
              matTooltip="Beheerpagina's alleen in NL beschikbaar"
              [matTooltipDisabled]="!isPrivatePage"
              [disabled]="isPrivatePage"
              [matMenuTriggerFor]="langMenu">
        <span class="flag"
              [style.background-image]="'url(../../assets/img/langs/' + activeLanguage + '.png)'"></span>
        ▾
      </button>

      <mat-menu #langMenu="matMenu">
        <ng-container *ngIf="i18nService.getLanguageAsObservable() | async as activeLanguage">
          <button *ngFor="let language of APP_LANGUAGES"
                  mat-menu-item
                  (click)="i18nService.setLanguage(language, true)">
            <mat-icon matListItemIcon
                      [class.color--calm]="language === activeLanguage"
                      [class.color--gray]="language !== activeLanguage">language
            </mat-icon>
            {{ APP_LANGUAGE_NATIVES[language] }}
          </button>
        </ng-container>
      </mat-menu>

      <button mat-stroked-button
              class="sidenav-trigger color--white"
              (click)="sidenav.open('mouse')">
        <mat-icon>menu</mat-icon>
        {{ 'generic.Menu' |translate }}
      </button>

    </mat-toolbar>

    <div class="app-main-content"
         [class.background--primary]="(showMainNav| async) === false">

      <router-outlet></router-outlet>

      <app-public-footer *ngIf="!isPrivatePage && !isApiOfflinePage"></app-public-footer>

    </div>
  </div>

</mat-sidenav-container>




